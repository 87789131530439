<template>
	<g>
		<transition name="quickfade">
			<g>
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#FFFFFF88"
					x="-160"
					y="-350"
					width="320"
					height="110"
					rx="15"
				/>
				<text
					opacity="1.0"
					x="0"
					y="-260"
					class="txt"
					text-anchor="middle"
					dominant-baseline="auto"
					font-family="sans-serif"
					font-size="96"
					fill="#000000CC"
					>{{ this.question }}</text
				>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="buttons" v-on:click="log_response('a')">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#77777788"
					x="-210"
					y="-210"
					width="200"
					height="200"
					rx="15"
				/>
				<text
					x="-110"
					y="-105"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
					>{{ "56" }}</text
				>
			</g>
		</transition>
		<transition>
			<g v-if="buttons" v-on:click="log_response('b')">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#77777788"
					x="10"
					y="-210"
					width="200"
					height="200"
					rx="15"
				/>
				<text
					x="110"
					y="-105"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
					>{{ "65" }}</text
				>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="buttons" v-on:click="log_response('a')">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#77777788"
					x="-210"
					y="10"
					width="200"
					height="200"
					rx="15"
				/>
				<text
					x="-110"
					y="115"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
					>{{ "49" }}</text
				>
			</g>
		</transition>
		<transition>
			<g v-if="buttons" v-on:click="log_response('b')">
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#77777788"
					x="10"
					y="10"
					width="200"
					height="200"
					rx="15"
				/>
				<text
					x="110"
					y="115"
					class="txt"
					text-anchor="middle"
					dominant-baseline="middle"
					font-family="sans-serif"
					font-size="60"
					fill="#FFFFFF"
					>{{ "64" }}</text
				>
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import persist from "@/persist.js";

export default {
	name: "test_arithmetic_multiple_choice",
	components: {
		//timeline,
		//instructions,
	},
	//props: ['stim_param'],
	//mixins: [lang],
	computed: {
		text() {
			return 0;
			//return this.getLocalisedText();
		},
		question() {
			return "7·8";
			// return (
			// 	this.items[this.trial] || {
			// 		block: -1,
			// 		task: "",
			// 		n: -2,
			// 		quad: -1,
			// 		stim: "0",
			// 		shift: 0,
			// 		comp: 0,
			// 		neg: 0,
			// 		cresp: "",
			// 	}
			//);
			//return this.items[this.trial] || [-1, '', -2, -1, 99, 0, 0, 0, '', '', ''];
		},
	},
	watch: {
		item: function () {
			//this.item_start_time = Date.now();
		},
		/*item_visible: function() {
			console.log('ready? ' + this.item_visible);
		}*/
	},
	data: () => {
		return {
			start_time: 0,
			show_buttons: true,
			input_disabled: false,
			items: [],
			trial: 0,
			buttons: true,
			balanced_list: [
				1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 7, 9, 2, 4, 6, 8, 2,
				4, 6, 8, 2, 4, 6, 8, 2, 4, 6, 8,
			],
		};
	},
	methods: {
		end_test() {
			this.show_buttons = false;
			setTimeout(() => {
				store.commit("setTaskMode", "clickToExit");
				this.saveSessionResults();
			}, 10000);
		},
		saveSessionResults() {
			const total_time = Date.now() - this.start_time;

			persist.set_progress_data("choke_multiple_choice_test", 1);

			persist.log("results", {
				score: 123,
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
			});
		},
		clicked_a() {
			this.log_response("a");
		},
		clicked_b() {
			this.log_response("b");
		},
		clicked_c() {
			this.log_response("c");
		},
		clicked_d() {
			this.log_response("d");
		},
		log_response(response) {
			if (response == "a") this.items.puch("a");
			// setTimeout(() => {
			// 	++this.trial;
			// 	if (this.trial >= this.items.length) this.end_test();
			// }, 150);
		},
		setup() {
			//store.commit('setTaskMode', 'playing');
			//store.commit('setTaskMode', 'readyImmediateInstruct');
			store.commit("setTaskMode", "ready_no_exit");
			store.commit("taskPhase", 0);
			this.$parent.show_buttons = false;
		},
	},
	mounted() {
		this.setup();
	},
};
</script>

<style lang="stylus">
.mini-balloon-enter-active, .mini-balloon-leave-active {
	transition: all 0.75s;
}

.mini-balloon-enter, .mini-balloon-leave-to {
	opacity: 0;
	transform: translate(350px, 10px);
}
</style>
