<template>
	<g>
		<transition name="quickfade">
			<g>
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#FFFFFF88"
					x="-300"
					y="-250"
					width="600"
					height="110"
					rx="15"
				/>
				<text
					opacity="1.0"
					x="25"
					y="-160"
					class="txt"
					text-anchor="end"
					dominant-baseline="auto"
					font-family="sans-serif"
					font-size="96"
					fill="#000000CC"
					>{{ question }}</text
				>
				<text
					opacity="1.0"
					x="50"
					y="-160"
					class="txt"
					text-anchor="start"
					dominant-baseline="auto"
					font-family="sans-serif"
					font-size="96"
					fill="#000000CC"
					>{{ answer }}</text
				>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="buttons">
				<g
					v-for="(v, idx) in alt"
					:key="idx"
					v-on:click="click_digit(idx)"
				>
					<rect
						stroke="#00000020"
						stroke-width="10"
						:fill="btn_colour(idx)"
						:x="v.x * 110 - 47"
						:y="v.y * 110 - 47 + 50"
						width="95"
						height="95"
						rx="15"
					/>
					<text
						:x="v.x * 110"
						:y="v.y * 110 + 56"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="50"
						fill="#FFFFFF"
						>{{ idx }}</text
					>
				</g>
				<g v-on:click="click_ok()">
					<rect
						stroke="#00000020"
						stroke-width="10"
						:fill="btn_ok_colour()"
						x="190"
						y="-80"
						width="120"
						height="120"
						rx="15"
					/>
					<text
						x="250"
						y="-17"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="40"
						fill="#FFFFFF"
						>OK</text
					>
				</g>
				<g v-on:click="click_erase()">
					<rect
						stroke="#00000020"
						stroke-width="10"
						:fill="btn_erase_colour()"
						x="190"
						y="60"
						width="120"
						height="120"
						rx="15"
					/>
					<text
						x="247"
						y="123"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="40"
						fill="#FFFFFF"
						>⌫</text
					>
				</g>
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import persist from "@/persist.js";

export default {
	name: "choke_practice_arithmetic",
	components: {
		//timeline,
		//instructions,
	},
	//props: ['stim_param'],
	//mixins: [lang],
	computed: {
		text() {
			return 0;
			//return this.getLocalisedText();
		},
		question() {
			return "7·8 =";
			// return (
			// 	this.items[this.trial] || {
			// 		block: -1,
			// 		task: "",
			// 		n: -2,
			// 		quad: -1,
			// 		stim: "0",
			// 		shift: 0,
			// 		comp: 0,
			// 		neg: 0,
			// 		cresp: "",
			// 	}
			//);
			//return this.items[this.trial] || [-1, '', -2, -1, 99, 0, 0, 0, '', '', ''];
		},
	},
	watch: {
		item: function () {
			//this.item_start_time = Date.now();
		},
		/*item_visible: function() {
			console.log('ready? ' + this.item_visible);
		}*/
	},
	data: () => {
		return {
			start_time: 0,
			show_buttons: true,
			input_disabled: false,
			items: [],
			trial: 0,
			buttons: true,
			alt: [
				{ x: 0, y: 2 },
				{ x: -1, y: -1 },
				{ x: 0, y: -1 },
				{ x: 1, y: -1 },
				{ x: -1, y: 0 },
				{ x: 0, y: 0 },
				{ x: 1, y: 0 },
				{ x: -1, y: 1 },
				{ x: 0, y: 1 },
				{ x: 1, y: 1 },
			],
			balanced_list: [
				1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 7, 9, 2, 4, 6, 8, 2,
				4, 6, 8, 2, 4, 6, 8, 2, 4, 6, 8,
			],
			btn_ok_down: false,
			btn_erase_down: false,
			btn_down: [
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
			],
			answer: "",
		};
	},
	methods: {
		click_digit(idx) {
			if (!this.btn_down[idx]) {
				if (this.answer.length < 3) {
					this.answer = this.answer + idx;
					this.$set(this.btn_down, idx, true);
					setTimeout(() => {
						this.$set(this.btn_down, idx, false);
					}, 100);
				}
			}
		},
		click_ok() {
			if (!this.btn_ok_down) {
				this.btn_ok_down = true;
				setTimeout(() => {
					this.btn_ok_down = false;
				}, 100);
			}
		},
		click_erase() {
			if (!this.btn_erase_down) {
				if (this.answer.length > 0) {
					this.answer = this.answer.substring(
						0,
						this.answer.length - 1
					);

					this.btn_erase_down = true;
					setTimeout(() => {
						this.btn_erase_down = false;
					}, 100);
				}
			}
		},
		btn_ok_colour() {
			if (this.answer.length == 0) return "#66BB6622";
			return this.btn_ok_down ? "#99EE99FF" : "#66BB66FF";
		},
		btn_erase_colour() {
			if (this.answer.length == 0) return "#BB666622";
			return this.btn_erase_down ? "#EE9999FF" : "#BB6666FF";
		},
		btn_colour(idx) {
			if (this.answer.length == 3) return "#bbbbaa22";
			return this.btn_down[idx] ? "#ffddaaff" : "#bbbbaaff";
		},
		end_test() {
			this.show_buttons = false;
			setTimeout(() => {
				store.commit("setTaskMode", "clickToExit");
				this.saveSessionResults();
			}, 10000);
		},
		saveSessionResults() {
			const total_time = Date.now() - this.start_time;

			persist.set_progress_data("choke_multiple_choice_test", 1);

			persist.log("results", {
				score: 123,
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
			});
		},
		clicked_a() {
			this.log_response("a");
		},
		clicked_b() {
			this.log_response("b");
		},
		clicked_c() {
			this.log_response("c");
		},
		clicked_d() {
			this.log_response("d");
		},
		log_response(response) {
			if (response == "a") this.items.push("a");
			if (response == "ok") {
				this.btn_ok_down = true;
				setTimeout(() => {
					this.btn_ok_down = false;
				}, 100);
			}
			// setTimeout(() => {
			// 	++this.trial;
			// 	if (this.trial >= this.items.length) this.end_test();
			// }, 150);
		},
		setup() {
			store.commit("setTaskMode", "playing");
			//store.commit('setTaskMode', 'readyImmediateInstruct');
			//store.commit("setTaskMode", "ready_no_exit");
			store.commit("taskPhase", 0);
			this.$parent.show_buttons = false;
		},
	},
	mounted() {
		this.setup();
	},
};
</script>

<style lang="stylus">
.mini-balloon-enter-active, .mini-balloon-leave-active {
	transition: all 0.75s;
}

.mini-balloon-enter, .mini-balloon-leave-to {
	opacity: 0;
	transform: translate(350px, 10px);
}
</style>
