<template><g></g></template>

<script>
import { store } from "@/store.js";
//import lang from "@/v/anx/menu/lang.js";
//import logoutButton from "@/v/taskMenu/logoutButton.vue";

export default {
	name: "home_choke",
	components: {
		//logoutButton,
	},
	//mixins: [lang],
	computed: {
		text() {
			return 0;
			// 	return this.getLocalisedText();
		},
	},
	data: () => {
		return {};
	},
	methods: {},
	mounted() {
		//store.dispatch("gotoModule", "test_arithmetic_multiple_choice");
		store.dispatch("gotoModule", "choke_practice_arithmetic");
	},
};
</script>
