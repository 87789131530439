<template>
	<svg id="app" width="100%" height="100%" viewBox="-800 -500 1600 1000">
		<circle opacity="0.05" fill="#EEEEEE" cx="0" cy="0" r="500" />
		<home v-if="this.$store.state.module == 'home'" />
		<login v-else-if="this.$store.state.module == 'login'" />
		<taskContainer v-else>
			<spatialSpan v-if="this.$store.state.module == 'spatialSpan'" />

			<finger_episodic_memory_spatial
				v-else-if="
					this.$store.state.module == 'finger_episodic_memory_spatial'
				"
			/>
			<finger_shifting_predictable
				v-else-if="
					this.$store.state.module == 'finger_shifting_predictable'
				"
			/>
			<finger_wm_maintenance
				v-else-if="this.$store.state.module == 'finger_wm_maintenance'"
			/>
			<finger_updating_spatial
				v-else-if="
					this.$store.state.module == 'finger_updating_spatial'
				"
			/>
			<finger_updating_digits
				v-else-if="this.$store.state.module == 'finger_updating_digits'"
			/>
			<finger_episodic_memory_relational
				v-else-if="
					this.$store.state.module ==
					'finger_episodic_memory_relational'
				"
			/>

			<mind_ad_episodic_memory_spatial
				v-else-if="
					this.$store.state.module ==
					'mind_ad_episodic_memory_spatial'
				"
			/>
			<mind_ad_shifting_predictable
				v-else-if="
					this.$store.state.module == 'mind_ad_shifting_predictable'
				"
			/>
			<mind_ad_wm_maintenance
				v-else-if="this.$store.state.module == 'mind_ad_wm_maintenance'"
			/>
			<mind_ad_updating_spatial
				v-else-if="
					this.$store.state.module == 'mind_ad_updating_spatial'
				"
			/>
			<mind_ad_updating_digits
				v-else-if="
					this.$store.state.module == 'mind_ad_updating_digits'
				"
			/>
			<mind_ad_episodic_memory_relational
				v-else-if="
					this.$store.state.module ==
					'mind_ad_episodic_memory_relational'
				"
			/>

			<ipark_updating_spatial
				v-else-if="this.$store.state.module == 'ipark_updating_spatial'"
			/>
			<ipark_keep_track
				v-else-if="this.$store.state.module == 'ipark_keep_track'"
			/>
			<ipark_updating_letters
				v-else-if="this.$store.state.module == 'ipark_updating_letters'"
			/>
			<ipark_updating_colours
				v-else-if="this.$store.state.module == 'ipark_updating_colours'"
			/>

			<reco_shifting_predictable
				v-else-if="
					this.$store.state.module == 'reco_shifting_predictable'
				"
			/>
			<reco_shifting_unpredictable
				v-else-if="
					this.$store.state.module == 'reco_shifting_unpredictable'
				"
			/>
			<reco_episodic_memory_relational
				v-else-if="
					this.$store.state.module ==
					'reco_episodic_memory_relational'
				"
			/>
			<reco_updating_letters
				v-else-if="this.$store.state.module == 'reco_updating_letters'"
			/>
			<reco_wm_maintenance
				v-else-if="this.$store.state.module == 'reco_wm_maintenance'"
			/>
			<reco_keep_track
				v-else-if="this.$store.state.module == 'reco_keep_track'"
			/>
			<reco_episodic_memory_spatial
				v-else-if="
					this.$store.state.module == 'reco_episodic_memory_spatial'
				"
			/>

			<choke_practice_arithmetic
				v-else-if="
					this.$store.state.module == 'choke_practice_arithmetic'
				"
			/>

			<test_bart v-else-if="this.$store.state.module == 'test_bart'" />
			<test_span_letters
				v-else-if="this.$store.state.module == 'test_span_letters'"
			/>
			<test_span_digits
				v-else-if="this.$store.state.module == 'test_span_digits'"
			/>
			<test_span_spatial
				v-else-if="this.$store.state.module == 'test_span_spatial'"
			/>
			<test_span_spatial_linear
				v-else-if="
					this.$store.state.module == 'test_span_spatial_linear'
				"
			/>
			<test_updating_digits
				v-else-if="this.$store.state.module == 'test_updating_digits'"
			/>
			<test_keep_track
				v-else-if="this.$store.state.module == 'test_keep_track'"
			/>
			<test_shifting_predictable
				v-else-if="
					this.$store.state.module == 'test_shifting_predictable'
				"
			/>
			<test_shifting_unpredictable
				v-else-if="
					this.$store.state.module == 'test_shifting_unpredictable'
				"
			/>
			<test_operation_span_verbal
				v-else-if="
					this.$store.state.module == 'test_operation_span_verbal'
				"
			/>
			<test_operation_span_math
				v-else-if="
					this.$store.state.module == 'test_operation_span_math'
				"
			/>
			<test_operation_span_math_adult
				v-else-if="
					this.$store.state.module == 'test_operation_span_math_adult'
				"
			/>
			<test_wisc4_mat
				v-else-if="this.$store.state.module == 'test_wisc4_mat'"
			/>
			<test_ravens
				v-else-if="this.$store.state.module == 'test_ravens'"
			/>
			<test_episodic_mal
				v-else-if="this.$store.state.module == 'test_episodic_mal'"
			/>

			<test_arithmetic_multiple_choice
				v-else-if="
					this.$store.state.module ==
					'test_arithmetic_multiple_choice'
				"
			/>

			<form_motivation
				v-else-if="this.$store.state.module == 'form_motivation'"
			/>
			<form_concentration
				v-else-if="this.$store.state.module == 'form_concentration'"
			/>
			<form_health
				v-else-if="this.$store.state.module == 'form_health'"
			/>
			<form_mental_effort
				v-else-if="this.$store.state.module == 'form_mental_effort'"
			/>
			<form_mental_fatigue
				v-else-if="this.$store.state.module == 'form_mental_fatigue'"
			/>
		</taskContainer>
		<errorView />
	</svg>
</template>

<script>
import persist from "@/persist.js";
import { store } from "@/store.js";
import errorView from "@/v/errorView/_.vue";
import login from "@/v/login/_.vue";
import home from "@/v/home/_.vue";
import taskContainer from "@/v/taskContainer/_.vue";

// training:
import spatialSpan from "@/v/spatialSpan/_.vue";

// Finger:
import finger_episodic_memory_spatial from "@/v/finger/episodic_memory_spatial/_.vue";
import finger_shifting_predictable from "@/v/finger/shifting_predictable/_.vue";
import finger_wm_maintenance from "@/v/finger/wm_maintenance/_.vue";
import finger_updating_spatial from "@/v/finger/updating_spatial/_.vue";
import finger_updating_digits from "@/v/finger/updating_digits/_.vue";
import finger_episodic_memory_relational from "@/v/finger/episodic_memory_relational/_.vue";

// Mind-AD:
import mind_ad_episodic_memory_spatial from "@/v/mind_ad/episodic_memory_spatial/_.vue";
import mind_ad_shifting_predictable from "@/v/mind_ad/shifting_predictable/_.vue";
import mind_ad_wm_maintenance from "@/v/mind_ad/wm_maintenance/_.vue";
import mind_ad_updating_spatial from "@/v/mind_ad/updating_spatial/_.vue";
import mind_ad_updating_digits from "@/v/mind_ad/updating_digits/_.vue";
import mind_ad_episodic_memory_relational from "@/v/mind_ad/episodic_memory_relational/_.vue";

// Reco:
import reco_shifting_predictable from "@/v/reco/shifting_predictable/_.vue";
import reco_shifting_unpredictable from "@/v/reco/shifting_unpredictable/_.vue";
import reco_episodic_memory_relational from "@/v/reco/episodic_memory_relational/_.vue";
import reco_updating_letters from "@/v/reco/updating_letters/_.vue";
import reco_wm_maintenance from "@/v/reco/wm_maintenance/_.vue";
import reco_keep_track from "@/v/reco/keep_track/_.vue";
import reco_episodic_memory_spatial from "@/v/reco/episodic_memory_spatial/_.vue";

// iPark:
import ipark_keep_track from "@/v/ipark/keep_track/_.vue";
import ipark_updating_letters from "@/v/ipark/updating_letters/_.vue";
import ipark_updating_colours from "@/v/ipark/updating_colours/_.vue";
import ipark_updating_spatial from "@/v/ipark/updating_spatial/_.vue";

// Choke:
import choke_practice_arithmetic from "@/v/choke/practice_arithmetic/_.vue";

// test:
import test_bart from "@/v/test_bart/_.vue";
import test_span_digits from "@/v/test_span_digits/_.vue";
import test_span_letters from "@/v/test_span_letters/_.vue";
import test_span_spatial from "@/v/test_span_spatial/_.vue";
import test_span_spatial_linear from "@/v/test_span_spatial_linear/_.vue";
import test_updating_digits from "@/v/test_updating_digits/_.vue";
import test_keep_track from "@/v/test_keep_track/_.vue";
import test_shifting_predictable from "@/v/test_shifting_predictable/_.vue";
import test_shifting_unpredictable from "@/v/test_shifting_unpredictable/_.vue";
import test_operation_span_verbal from "@/v/test_operation_span_verbal/_.vue";
import test_operation_span_math from "@/v/test_operation_span_math/_.vue";
import test_operation_span_math_adult from "@/v/test_operation_span_math_adult/_.vue";
import test_wisc4_mat from "@/v/test_wisc4_mat/_.vue";
import test_ravens from "@/v/test_ravens/_.vue";
import test_episodic_mal from "@/v/test_episodic_modified_associative_learning/_.vue";
import test_arithmetic_multiple_choice from "@/v/test_arithmetic_multiple_choice/_.vue";

// forms:
import form_motivation from "@/v/form_motivation/_.vue";
import form_concentration from "@/v/form_concentration/_.vue";
import form_health from "@/v/form_health/_.vue";
import form_mental_effort from "@/v/form_mental_effort/_.vue";
import form_mental_fatigue from "@/v/form_mental_fatigue/_.vue";

// OBS! Har nu lagt till meta-headers i index.html för att disabla browser-cachen.
// Dåligt för PWA etc?

//const spatialSpan = () => {
//	// Load dynamically
//	return import("@/v/spatialSpan/_.vue");
//};

/*
window.onerror = function(message, source, lineno, colno, error) {
	console.log(`err: ${error}.`);
	console.log(`msg: ${message}.`);
	console.log(`src: ${source}.`);
	console.log(`lin: ${lineno}.`);
	console.log(`col: ${colno}.`);
	this.$store.commit("showError", `${error}`);
	// TODO: write any custom logic or logs the error
	return true;
};*/

export default {
	name: "app",
	components: {
		errorView,
		taskContainer,
		// Test
		test_bart,
		test_span_digits,
		test_span_letters,
		test_span_spatial,
		test_span_spatial_linear,
		test_shifting_predictable,
		test_shifting_unpredictable,
		test_updating_digits,
		test_keep_track,
		test_operation_span_verbal,
		test_operation_span_math,
		test_operation_span_math_adult,
		test_wisc4_mat,
		test_ravens,
		test_episodic_mal,
		test_arithmetic_multiple_choice,
		spatialSpan,
		// iPark
		ipark_updating_spatial,
		ipark_keep_track,
		ipark_updating_letters,
		ipark_updating_colours,
		// Finger
		finger_episodic_memory_spatial,
		finger_shifting_predictable,
		finger_wm_maintenance,
		finger_updating_spatial,
		finger_updating_digits,
		finger_episodic_memory_relational,
		// Mind-AD
		mind_ad_episodic_memory_spatial,
		mind_ad_shifting_predictable,
		mind_ad_wm_maintenance,
		mind_ad_updating_spatial,
		mind_ad_updating_digits,
		mind_ad_episodic_memory_relational,
		// RECO
		reco_shifting_predictable,
		reco_shifting_unpredictable,
		reco_episodic_memory_relational,
		reco_updating_letters,
		reco_wm_maintenance,
		reco_keep_track,
		reco_episodic_memory_spatial,
		// CHOKE
		choke_practice_arithmetic,
		// Enkäter
		form_motivation,
		form_concentration,
		form_health,
		form_mental_effort,
		form_mental_fatigue,
		// etc..
		login,
		home,
	},
	methods: {
		goto(module) {
			store.dispatch("gotoModule", module);
		},
	},
	mounted() {
		//spatialSpan(); // preload spatialSpan
		//setTimeout(() => store.dispatch("gotoModule", "spatialSpan"), 100);

		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			this.$store.commit("setMobileBrowser", true);
		} else {
			this.$store.commit("setMobileBrowser", false);
		}

		/* eslint-disable no-console */
		persist.init(this, this.$shared);
		persist
			.login()
			.then((ready) => {
				store.dispatch("gotoModule", ready ? "home" : "login"); //test_span_spatial spatialSpan
			})
			.catch((e) => {
				console.error("login error " + e);
				store.dispatch("gotoModule", "login");
			});
		/* eslint-enable no-console */

		//document.title = this.text.windowTitle;
		//console.log(this.$shared.text.mainScreen.welcomeBack("Nisse"));

		window.addEventListener("beforeunload", (event) => {
			if (
				this.$store.state.module == "home" ||
				this.$store.state.module == "login" ||
				this.$store.state.module == "" ||
				this.$store.state.taskContainer.showExitButton ||
				this.$store.state.taskContainer.showInstructions ||
				this.$store.state.module == "test_operation_span_math" ||
				this.$store.state.module == "test_span_spatial_linear"
			) {
				delete event["returnValue"];
			} else {
				//persist.log("leave_page?", {});
				event.preventDefault();
				event.returnValue = "x";
			}
		});
	},
};
</script>

<style lang="stylus">
body {
	margin: 0;
	background: #acd;
	touch-action: none;
}

#app {
	user-select: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	position: absolute;
	margin: 0;
	width: 100%;
	height: 100%;
	background: #acd;
	left: 0px;
	top: 0px;
	z-index: 0;
	position: fixed;
}

* {
	-webkit-tap-highlight-color: transparent;
}

.txtbox {
	cursor: default;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 10px;
	padding-bottom: 3px;
	background-color: #33556688;
	border-radius: 25px;
	user-select: none;
	text-align: center;
	text-text-justify: auto;
	font-size: 32px;
	font-family: sans-serif;
	color: #FFFFFF;
	letter-spacing: 0.025em;
	text-shadow: -1px -1px 0 #777, 0 -1px 0 #777, 1px -1px 0 #777, 1px 0 0 #777, 1px 1px 0 #777, 0 1px 0 #777, -1px 1px 0 #777, -1px 0 0 #777, -1px -1px 12px #777;
}

.readbox {
	cursor: default;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: 40px;
	margin-right: 40px;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 10px;
	padding-bottom: 3px;
	background-color: #44667788;
	border-radius: 25px;
	user-select: none;
	text-align: center;
	text-text-justify: auto;
	font-size: 32px;
	font-family: sans-serif;
	color: #FFFFFFDD;
	letter-spacing: 0.025em;
	text-shadow: -1px -1px 0 #777, 0 -1px 0 #777, 1px -1px 0 #777, 1px 0 0 #777, 1px 1px 0 #777, 0 1px 0 #777, -1px 1px 0 #777, -1px 0 0 #777, -1px -1px 12px #777;
}

.bigbox {
	cursor: default;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: 40px;
	margin-right: 40px;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: #44667788;
	border-radius: 25px;
	user-select: none;
	text-align: center;
	text-text-justify: auto;
	font-size: 64px;
	font-family: sans-serif;
	color: #FFFFFFDD;
	letter-spacing: 0.025em;
	text-shadow: -1px -1px 0 #777, 0 -1px 0 #777, 1px -1px 0 #777, 1px 0 0 #777, 1px 1px 0 #777, 0 1px 0 #777, -1px 1px 0 #777, -1px 0 0 #777, -1px -1px 12px #777;
}

.title {
	cursor: default;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: #00000011;
	border-radius: 25px;
	user-select: none;
	text-align: center;
	text-text-justify: auto;
	font-size: 90px;
	font-family: sans-serif;
	color: #FFFFFF;
	letter-spacing: 0.05em;
}

.hint {
	cursor: default;
	user-select: none;
	text-align: center;
	text-text-justify: auto;
	font-size: 32px;
	font-family: sans-serif;
	color: #00000077;
	letter-spacing: 0.05em;
}

.link {
	cursor: pointer;
	margin: 0.25em;
	margin-top: 15px;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: #FFFFFF22;
	border-radius: 25px;
	border: 5px solid #FFFFFFAA;
	user-select: none;
	font-size: 32px;
	font-family: sans-serif;
	color: #FFFFFFDD;
	letter-spacing: 0.025em;
	text-shadow: -1px -1px 0 #777, 0 -1px 0 #777, 1px -1px 0 #777, 1px 0 0 #777, 1px 1px 0 #777, 0 1px 0 #777, -1px 1px 0 #777, -1px 0 0 #777, -1px -1px 12px #777;
}

.clickable {
	cursor: pointer;
}

.txt {
	border-radius: 25px;
	border: 5px solid #FFFFFFAA;
	user-select: none;
	font-family: sans-serif;
	color: #FFFFFFDD;
	text-shadow: none;
}

.heading {
	cursor: default;
	user-select: none;
	text-align: center;
	text-text-justify: auto;
	font-size: 70px;
	font-family: sans-serif;
	color: #FFFFFF;
}

.cursorHide {
	cursor: none;
}

.cursorPointer {
	cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
	transition: opacity 2s;
}

.fade-enter, .fade-leave-to {
	opacity: 0;
}

.quickfade-enter-active, .quickfade-leave-active {
	transition: opacity 0.25s;
}

.quickfade-enter, .quickfade-leave-to {
	opacity: 0;
}
</style>
