var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{attrs:{"id":"app","width":"100%","height":"100%","viewBox":"-800 -500 1600 1000"}},[_c('circle',{attrs:{"opacity":"0.05","fill":"#EEEEEE","cx":"0","cy":"0","r":"500"}}),(this.$store.state.module == 'home')?_c('home'):(this.$store.state.module == 'login')?_c('login'):_c('taskContainer',[(this.$store.state.module == 'spatialSpan')?_c('spatialSpan'):(
				this.$store.state.module == 'finger_episodic_memory_spatial'
			)?_c('finger_episodic_memory_spatial'):(
				this.$store.state.module == 'finger_shifting_predictable'
			)?_c('finger_shifting_predictable'):(this.$store.state.module == 'finger_wm_maintenance')?_c('finger_wm_maintenance'):(
				this.$store.state.module == 'finger_updating_spatial'
			)?_c('finger_updating_spatial'):(this.$store.state.module == 'finger_updating_digits')?_c('finger_updating_digits'):(
				this.$store.state.module ==
				'finger_episodic_memory_relational'
			)?_c('finger_episodic_memory_relational'):(
				this.$store.state.module ==
				'mind_ad_episodic_memory_spatial'
			)?_c('mind_ad_episodic_memory_spatial'):(
				this.$store.state.module == 'mind_ad_shifting_predictable'
			)?_c('mind_ad_shifting_predictable'):(this.$store.state.module == 'mind_ad_wm_maintenance')?_c('mind_ad_wm_maintenance'):(
				this.$store.state.module == 'mind_ad_updating_spatial'
			)?_c('mind_ad_updating_spatial'):(
				this.$store.state.module == 'mind_ad_updating_digits'
			)?_c('mind_ad_updating_digits'):(
				this.$store.state.module ==
				'mind_ad_episodic_memory_relational'
			)?_c('mind_ad_episodic_memory_relational'):(this.$store.state.module == 'ipark_updating_spatial')?_c('ipark_updating_spatial'):(this.$store.state.module == 'ipark_keep_track')?_c('ipark_keep_track'):(this.$store.state.module == 'ipark_updating_letters')?_c('ipark_updating_letters'):(this.$store.state.module == 'ipark_updating_colours')?_c('ipark_updating_colours'):(
				this.$store.state.module == 'reco_shifting_predictable'
			)?_c('reco_shifting_predictable'):(
				this.$store.state.module == 'reco_shifting_unpredictable'
			)?_c('reco_shifting_unpredictable'):(
				this.$store.state.module ==
				'reco_episodic_memory_relational'
			)?_c('reco_episodic_memory_relational'):(this.$store.state.module == 'reco_updating_letters')?_c('reco_updating_letters'):(this.$store.state.module == 'reco_wm_maintenance')?_c('reco_wm_maintenance'):(this.$store.state.module == 'reco_keep_track')?_c('reco_keep_track'):(
				this.$store.state.module == 'reco_episodic_memory_spatial'
			)?_c('reco_episodic_memory_spatial'):(
				this.$store.state.module == 'choke_practice_arithmetic'
			)?_c('choke_practice_arithmetic'):(this.$store.state.module == 'test_bart')?_c('test_bart'):(this.$store.state.module == 'test_span_letters')?_c('test_span_letters'):(this.$store.state.module == 'test_span_digits')?_c('test_span_digits'):(this.$store.state.module == 'test_span_spatial')?_c('test_span_spatial'):(
				this.$store.state.module == 'test_span_spatial_linear'
			)?_c('test_span_spatial_linear'):(this.$store.state.module == 'test_updating_digits')?_c('test_updating_digits'):(this.$store.state.module == 'test_keep_track')?_c('test_keep_track'):(
				this.$store.state.module == 'test_shifting_predictable'
			)?_c('test_shifting_predictable'):(
				this.$store.state.module == 'test_shifting_unpredictable'
			)?_c('test_shifting_unpredictable'):(
				this.$store.state.module == 'test_operation_span_verbal'
			)?_c('test_operation_span_verbal'):(
				this.$store.state.module == 'test_operation_span_math'
			)?_c('test_operation_span_math'):(
				this.$store.state.module == 'test_operation_span_math_adult'
			)?_c('test_operation_span_math_adult'):(this.$store.state.module == 'test_wisc4_mat')?_c('test_wisc4_mat'):(this.$store.state.module == 'test_ravens')?_c('test_ravens'):(this.$store.state.module == 'test_episodic_mal')?_c('test_episodic_mal'):(
				this.$store.state.module ==
				'test_arithmetic_multiple_choice'
			)?_c('test_arithmetic_multiple_choice'):(this.$store.state.module == 'form_motivation')?_c('form_motivation'):(this.$store.state.module == 'form_concentration')?_c('form_concentration'):(this.$store.state.module == 'form_health')?_c('form_health'):(this.$store.state.module == 'form_mental_effort')?_c('form_mental_effort'):(this.$store.state.module == 'form_mental_fatigue')?_c('form_mental_fatigue'):_vm._e()],1),_c('errorView')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }