import stat from "@/stat.js";

export const taskContainer = {
	state: {
		taskMode: "ready",
		taskPlaying: false,
		showPlayButton: false,
		showContinueButton: false,
		showExitButton: false,
		showInstructions: false,
		taskPhase: -1,
		showSessionScore: false,
		sessionScores: [],
		moduleName: "",
		scoreName: "",
		iparkListLengths: [], // TODO: hör inte hemma här..
	},
	mutations: {
		setTaskMode(state, taskMode) {
			state.taskMode = taskMode;
			switch (taskMode) {
			case "exit":
				state.taskPlaying = false;
				state.showExitButton = false;
				state.showPlayButton = false;
				state.showContinueButton = false;
				state.taskPhase = -1;
				state.showSessionScore = false;
				state.showInstructions = false;
				break;
			case "ready":
				state.taskPlaying = false;
				state.showExitButton = true;
				state.showPlayButton = true; // TODO: instructions viewed?
				state.showContinueButton = false;
				state.taskPhase = 0;
				state.showSessionScore = false;
				state.showInstructions = true;
				break;
			case "ready_no_exit":
				state.taskPlaying = false;
				state.showExitButton = false;
				state.showPlayButton = true;
				state.showContinueButton = false;
				state.taskPhase = 0;
				state.showSessionScore = false;
				state.showInstructions = true;
				break;
			case "ready_continue":
				state.taskPlaying = false;
				state.showExitButton = false;
				state.showPlayButton = false;
				state.showContinueButton = true;
				state.taskPhase = 0;
				state.showSessionScore = false;
				state.showInstructions = true;
				break;
			case "readyForNextRound":
				state.taskPlaying = false;
				state.showExitButton = false;
				state.showPlayButton = true;
				state.showContinueButton = false; // TODO: kanske använd denna istället? Kolla alla mods
				state.taskPhase = 0;
				state.showSessionScore = false;
				state.showInstructions = false;
				break;
			case "readyImmediate":
				state.taskPlaying = true;
				state.showExitButton = true;
				state.showPlayButton = false;
				state.showContinueButton = false;
				state.taskPhase = 1;
				state.showSessionScore = false;
				state.showInstructions = false;
				break;
			case "readyImmediateInstruct":
				state.taskPlaying = true;
				state.showExitButton = true;
				state.showPlayButton = false;
				state.showContinueButton = false;
				state.taskPhase = 1;
				state.showSessionScore = false;
				state.showInstructions = true;
				break;
			case "auto":
				state.taskPlaying = false;
				state.showExitButton = false;
				state.showPlayButton = false;
				state.showContinueButton = false;
				state.taskPhase = 0;
				state.showSessionScore = false;
				state.showInstructions = false;
				break;
			case "playing":
				state.taskPlaying = true;
				state.showExitButton = false;
				state.showPlayButton = false;
				state.showContinueButton = false;
				state.taskPhase = 1;
				state.showSessionScore = false;
				state.showInstructions = false;
				break;
			case "instructions":
				state.taskPlaying = false;
				state.showExitButton = false;
				state.showPlayButton = false;
				state.showContinueButton = false;
				state.taskPhase = 1;
				state.showSessionScore = false;
				state.showInstructions = true;
				break;
			case "roundScore":
				state.taskPlaying = false;
				state.showExitButton = false;
				state.showPlayButton = false;
				state.showContinueButton = false;
				state.taskPhase = -1;
				state.showSessionScore = false;
				state.showInstructions = false;
				break;
			case "sessionScore":
				state.taskPlaying = false;
				state.showExitButton = true;
				state.showPlayButton = false;
				state.showContinueButton = false;
				state.taskPhase = -1;
				state.showSessionScore = true;
				state.showInstructions = false;
				break;
			case "clickToExit":
				state.taskPlaying = false;
				state.showExitButton = true;
				state.showPlayButton = false;
				state.showContinueButton = false;
				state.taskPhase = -1;
				state.showSessionScore = false;
				state.showInstructions = false;
				break;
			default:
				throw `unknown taskMode: ${taskMode}.`;
			}
		},
		taskPhase(state, nextPhase) {
			state.taskPhase = nextPhase;
		},
		nextTaskPhase(state) {
			state.taskPhase = 1 + state.taskPhase;
		},
		showContinueButton(state) {
			state.showContinueButton = true;
		},
		hideContinueButton(state) {
			state.showContinueButton = false;
		},
		setSessionScores(state, scoresArray) {
			state.sessionScores = scoresArray;
		},
		appendSessionScore(state, score) {
			state.sessionScores.push(score);
		},
		setModuleName(state, moduleName) {
			state.moduleName = moduleName;
		},
		setScoreName(state, scoreName) {
			state.scoreName = scoreName;
		},
		ipark_getListLength(state, level, sessionNumber) {
			var arr;
			switch (level) {
			case 0:
				arr = [4, 5, 6, 6, 7]; break; // lowLevelLengths
			case 1:
				arr = [6, 7, 8, 9, 10]; break; // midLevelLengths
			case 2:
				arr = sessionNumber % 2 == 0 ? [5, 7, 9, 11, 13] : [6, 8, 10, 12, 14]; break; // higLevelLengths: jämna/udda sessioner
			default:
				arr = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14]; break; // critLengths
			}
			state.iparkListLengths = stat.shuffle(arr.slice(0));
		}
	},
	//store.dispatch("nextTaskPhase,
	actions: {
		nextTaskPhase({ commit, state }, ms = 0) {
			const next = state.taskPhase + 1;
			if (ms == 0) {
				commit('taskPhase', next);
			} else {
				setTimeout(() => {
					commit('taskPhase', next); 
				}, ms);
			}
		},
		gotoTaskPhase({commit}, {next, ms = 0}) {
			if (ms == 0) {
				commit('taskPhase', next);
			} else {
				setTimeout(() => { commit('taskPhase', next); }, ms);
			}
		}
	}
}
